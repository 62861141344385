import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 900.000000 900.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,900.000000) scale(0.100000,-0.100000)">
<path d="M3538 6890 c-179 -22 -339 -169 -377 -348 -15 -70 -15 -1937 0 -2001
28 -124 126 -245 242 -300 48 -23 89 -33 158 -41 120 -12 1753 -13 1873 0 210
22 369 163 406 359 14 73 14 1899 0 1972 -17 94 -73 193 -141 249 -69 57 -103
77 -174 99 -43 13 -174 15 -985 17 -514 1 -965 -2 -1002 -6z m1168 -380 c136
-12 251 -42 353 -93 247 -120 411 -348 467 -644 25 -137 15 -406 -20 -532 -76
-270 -255 -482 -491 -580 -167 -70 -182 -72 -692 -78 l-463 -6 0 327 0 327
-196 -2 c-108 -1 -199 3 -203 7 -4 5 -5 35 -3 67 l4 57 269 0 269 0 0 -321 0
-320 388 3 c372 3 390 4 467 27 271 80 448 266 521 548 30 115 28 179 -8 281
-32 92 -131 193 -242 247 -43 20 -80 34 -83 31 -3 -3 1 -20 10 -38 53 -113 71
-286 43 -411 -44 -195 -164 -325 -343 -372 -41 -11 -112 -15 -255 -15 l-198 0
0 246 0 245 -217 -2 -218 -2 -3 67 -3 66 290 0 291 0 2 -242 3 -243 114 4
c186 5 267 36 338 129 83 110 95 323 27 469 -14 29 -46 71 -75 97 -84 76 -97
78 -494 86 -387 8 -408 11 -537 76 -86 43 -189 141 -235 224 -29 53 -123 261
-123 274 0 10 1135 6 1246 -4z"/>
<path d="M3696 6331 c30 -57 113 -143 171 -178 21 -13 63 -33 93 -45 51 -20
78 -22 415 -29 391 -8 514 -21 673 -75 95 -31 236 -106 279 -147 18 -17 35
-28 38 -25 7 6 -24 87 -61 161 -66 131 -171 231 -319 302 -161 77 -160 77
-774 82 l-543 5 28 -51z"/>
<path d="M870 3641 l0 -201 78 0 c82 1 140 16 174 47 59 52 76 176 35 254 -34
64 -82 87 -196 96 l-91 6 0 -202z m197 95 c44 -52 38 -179 -11 -211 -12 -8
-40 -16 -62 -18 l-39 -2 -3 119 c-1 66 0 126 3 134 9 24 85 8 112 -22z"/>
<path d="M1344 3793 c-16 -44 -119 -312 -131 -340 -4 -9 8 -13 39 -13 43 0 46
2 61 40 l16 41 73 -3 73 -3 11 -35 c11 -33 15 -35 58 -38 l46 -3 -19 50 c-11
27 -37 93 -57 148 -20 54 -47 122 -60 151 -21 48 -25 52 -58 52 -32 0 -36 -3
-52 -47z m74 -115 c39 -105 40 -98 -17 -98 -28 0 -51 3 -51 6 0 11 42 144 45
144 2 0 12 -24 23 -52z"/>
<path d="M1638 3835 c-5 -5 -8 -95 -8 -202 l0 -193 46 0 45 0 -2 68 -3 67 35
3 c89 7 138 23 164 53 46 55 27 152 -36 184 -33 18 -229 33 -241 20z m206 -84
c24 -20 20 -67 -6 -85 -23 -16 -118 -23 -119 -8 0 4 -2 27 -4 52 -2 24 -1 48
2 53 8 13 109 3 127 -12z"/>
<path d="M2000 3641 l0 -201 45 0 45 0 0 70 0 70 41 0 c110 0 179 46 179 120
0 98 -45 129 -200 137 l-110 6 0 -202z m214 110 c24 -20 20 -67 -6 -85 -23
-16 -118 -23 -119 -8 -4 64 -4 104 1 108 9 10 108 -2 124 -15z"/>
<path d="M2350 3830 c0 -6 28 -64 63 -128 60 -113 62 -120 62 -189 l0 -73 43
0 42 0 0 72 c0 69 2 76 65 195 69 130 70 138 9 131 -30 -3 -30 -3 -77 -103
-18 -38 -36 -70 -41 -70 -5 0 -25 38 -46 85 l-37 85 -42 3 c-26 2 -41 -1 -41
-8z"/>
<path d="M2994 3830 c-85 -20 -128 -86 -128 -196 1 -55 6 -80 24 -112 61 -108
235 -108 289 1 l21 42 -37 3 c-31 3 -40 -1 -60 -27 -21 -26 -31 -31 -67 -31
-62 0 -79 24 -84 111 -7 114 26 158 105 143 23 -4 38 -15 49 -35 12 -24 21
-29 49 -29 41 0 45 18 15 67 -31 52 -109 80 -176 63z"/>
<path d="M3371 3830 c-81 -19 -131 -94 -131 -196 0 -83 30 -141 90 -171 24
-13 60 -23 80 -23 55 0 121 38 147 85 27 48 31 153 9 206 -16 39 -61 84 -91
92 -48 12 -72 14 -104 7z m103 -90 c27 -27 34 -131 13 -183 -17 -38 -42 -51
-92 -45 -82 9 -103 200 -27 243 33 18 80 12 106 -15z"/>
<path d="M3650 3640 l0 -200 41 0 41 0 -2 133 c-2 72 -2 137 -1 142 1 6 24
-50 51 -124 27 -74 54 -139 59 -144 5 -5 19 -7 31 -5 19 2 31 25 75 141 29 76
56 141 59 144 5 5 3 -63 -9 -224 l-4 -63 45 0 44 0 -2 198 -3 197 -34 3 c-43
4 -81 -5 -81 -19 0 -30 -94 -270 -100 -257 -4 7 -29 73 -55 146 l-48 132 -53
0 -54 0 0 -200z"/>
<path d="M4157 3827 c-3 -8 -6 -98 -7 -201 l-1 -186 45 0 46 0 -7 143 c-3 78
-3 140 0 137 3 -3 28 -66 55 -140 47 -130 51 -135 79 -138 30 -3 30 -2 77 125
25 70 51 135 56 143 6 9 7 -38 3 -128 l-6 -142 41 0 42 0 0 201 0 200 -52 -3
-53 -3 -49 -137 c-27 -76 -52 -138 -56 -138 -4 0 -30 62 -58 138 l-50 137 -50
3 c-35 2 -51 -1 -55 -11z"/>
<path d="M4654 3826 c-3 -9 -4 -78 -2 -154 2 -111 6 -143 21 -165 29 -45 65
-62 131 -62 69 0 109 17 140 58 19 26 21 42 21 180 l0 152 -40 0 -40 0 4 -54
c6 -91 -9 -240 -26 -256 -16 -17 -80 -20 -103 -5 -12 7 -16 41 -20 162 l-5
153 -38 3 c-26 2 -39 -1 -43 -12z"/>
<path d="M5040 3640 l0 -200 40 0 39 0 3 128 3 127 79 -127 c79 -128 79 -128
117 -128 35 0 39 3 44 33 3 17 5 106 3 197 l-3 165 -39 0 -38 0 1 -128 c1 -78
-2 -127 -7 -123 -5 3 -42 59 -83 123 -40 65 -78 121 -83 125 -6 4 -26 8 -43 8
l-33 0 0 -200z"/>
<path d="M5450 3640 l0 -200 40 0 40 0 0 200 0 200 -40 0 -40 0 0 -200z"/>
<path d="M5723 3828 c-126 -28 -169 -248 -67 -345 81 -76 245 -40 269 60 7 26
6 27 -34 27 -23 0 -41 -4 -41 -10 0 -5 -9 -19 -20 -30 -27 -27 -93 -27 -119
-1 -36 36 -39 155 -6 208 29 44 114 37 137 -12 9 -20 18 -25 50 -25 42 0 45 7
22 56 -29 61 -108 91 -191 72z"/>
<path d="M6099 3813 c-6 -16 -38 -100 -71 -188 -33 -88 -63 -166 -65 -172 -4
-9 8 -13 40 -13 41 0 45 3 52 27 11 45 23 53 85 53 77 0 88 -6 96 -46 6 -33 8
-34 54 -34 l47 0 -64 168 c-93 244 -86 232 -128 232 -29 0 -37 -5 -46 -27z
m79 -160 c11 -34 18 -64 14 -67 -11 -12 -92 -6 -92 7 0 24 44 137 51 130 3 -5
16 -36 27 -70z"/>
<path d="M6350 3805 l0 -34 63 -3 62 -3 3 -162 2 -163 40 0 40 0 0 165 0 165
60 0 60 0 0 35 0 35 -165 0 -165 0 0 -35z"/>
<path d="M6740 3640 l0 -200 40 0 40 0 0 200 0 200 -40 0 -40 0 0 -200z"/>
<path d="M7006 3826 c-81 -30 -116 -91 -116 -202 0 -79 29 -131 90 -161 54
-28 101 -29 156 -4 60 27 87 72 92 148 6 83 -4 126 -35 163 -48 57 -122 79
-187 56z m89 -66 c38 -14 59 -71 52 -143 -7 -78 -34 -109 -91 -105 -56 4 -86
49 -86 127 1 63 16 97 51 117 29 16 40 17 74 4z"/>
<path d="M7300 3641 l0 -201 40 0 40 0 0 125 c0 69 3 125 8 125 4 -1 41 -57
82 -125 l75 -125 43 0 42 0 0 200 0 200 -40 0 -39 0 -3 -132 -3 -133 -81 130
-81 130 -42 3 -41 3 0 -200z"/>
<path d="M7765 3815 c-104 -56 -75 -159 56 -200 76 -24 103 -46 94 -74 -10
-32 -51 -45 -97 -31 -26 7 -38 17 -38 29 0 22 -19 31 -61 31 -24 0 -29 -4 -29
-24 0 -32 52 -83 98 -96 113 -31 212 18 212 106 0 38 -54 85 -122 107 -74 23
-107 49 -92 73 26 43 106 42 126 -2 10 -21 17 -25 47 -22 48 4 51 28 8 75 -33
35 -39 37 -103 41 -49 2 -78 -2 -99 -13z"/>
<path d="M1852 2963 l3 -78 46 -3 c35 -2 49 2 64 18 15 16 16 25 7 44 -6 13
-8 33 -5 45 9 34 -14 51 -69 51 l-49 0 3 -77z m91 43 c7 -18 -12 -36 -39 -36
-23 0 -34 16 -26 38 6 16 59 15 65 -2z m10 -70 c8 -20 -13 -36 -45 -36 -23 0
-28 4 -28 25 0 22 4 25 34 25 20 0 36 -6 39 -14z"/>
<path d="M2203 3025 c-10 -27 4 -105 22 -125 22 -25 56 -26 83 -2 25 21 38
142 16 142 -9 0 -14 -20 -16 -61 -2 -46 -8 -65 -20 -73 -34 -21 -53 4 -56 73
-3 58 -16 79 -29 46z"/>
<path d="M2560 3035 c0 -2 14 -26 30 -52 17 -26 30 -60 30 -76 0 -16 4 -26 10
-22 6 3 10 19 10 34 0 15 14 49 30 75 17 26 25 46 18 44 -7 -3 -22 -19 -33
-36 -11 -17 -22 -31 -25 -31 -3 -1 -15 15 -28 34 -19 30 -42 46 -42 30z"/>
<path d="M2920 2960 c0 -47 4 -80 10 -80 6 0 10 28 11 63 0 61 0 62 17 38 48
-70 46 -69 78 -23 16 23 31 41 34 42 3 0 3 -27 2 -60 -3 -39 0 -60 7 -60 7 0
11 29 11 80 0 46 -4 80 -10 80 -5 0 -22 -18 -36 -40 -15 -22 -30 -40 -35 -40
-5 0 -21 18 -35 40 -42 63 -54 54 -54 -40z"/>
<path d="M3345 3015 c-58 -57 -3 -146 80 -131 37 7 61 30 70 67 5 19 0 34 -20
57 -35 42 -93 45 -130 7z m103 -11 c28 -19 29 -57 2 -84 -27 -27 -56 -25 -85
5 -30 29 -31 35 -4 69 23 29 54 33 87 10z"/>
<path d="M3727 3033 c-4 -3 -7 -39 -7 -80 0 -58 3 -73 15 -73 9 0 15 9 15 24
0 39 21 42 51 8 34 -38 56 -42 33 -7 -15 24 -15 27 0 44 20 22 21 55 0 75 -15
16 -94 23 -107 9z m93 -28 c20 -24 4 -45 -35 -45 -32 0 -35 2 -35 30 0 26 3
30 29 30 16 0 34 -7 41 -15z"/>
<path d="M4080 2960 l0 -80 55 0 c65 0 74 17 13 22 -36 2 -44 7 -46 26 -3 20
1 22 37 22 51 0 54 18 4 22 -31 2 -39 7 -41 26 -3 20 1 22 37 22 22 0 43 5 46
10 4 6 -15 10 -49 10 l-56 0 0 -80z"/>
<path d="M4683 3033 c-6 -5 -13 -17 -15 -28 -2 -15 8 -25 41 -42 63 -32 69
-40 38 -54 -22 -10 -30 -8 -56 10 -35 25 -42 12 -11 -19 25 -25 61 -26 91 -2
31 26 20 48 -36 73 -25 11 -45 24 -45 30 0 19 32 22 68 5 l35 -17 -15 23 c-16
24 -71 36 -95 21z"/>
<path d="M5020 2960 c0 -47 4 -80 10 -80 6 0 10 14 10 31 0 16 4 28 9 25 5 -3
23 -3 41 0 43 9 59 44 36 79 -14 21 -24 25 -61 25 l-45 0 0 -80z m85 30 c0
-21 -5 -26 -32 -28 -31 -3 -33 -1 -33 28 0 29 2 31 33 28 27 -2 32 -7 32 -28z"/>
<path d="M5362 2963 l3 -78 53 -3 c30 -2 52 1 52 7 0 5 -19 11 -42 13 -35 2
-43 7 -43 23 0 16 8 21 38 23 20 2 37 7 37 12 0 5 -17 10 -37 12 -30 2 -38 7
-38 23 0 16 8 21 43 23 62 5 51 22 -13 22 l-56 0 3 -77z"/>
<path d="M5700 2960 c0 -94 18 -108 22 -17 l3 62 44 -62 c61 -85 71 -83 71 17
0 47 -4 80 -10 80 -10 0 -15 -41 -11 -92 1 -16 0 -28 -3 -28 -2 0 -24 27 -47
60 -58 83 -69 79 -69 -20z"/>
<path d="M6080 2960 l0 -80 29 0 c41 0 90 19 101 40 32 59 -11 120 -85 120
l-45 0 0 -80z m100 40 c11 -11 20 -29 20 -39 0 -25 -45 -61 -76 -61 -23 0 -24
4 -24 60 l0 60 30 0 c17 0 39 -9 50 -20z"/>
<path d="M6450 2960 l0 -80 49 0 c28 0 53 5 56 10 4 6 -12 10 -39 10 l-46 0 0
70 c0 40 -4 70 -10 70 -6 0 -10 -33 -10 -80z"/>
<path d="M6780 2960 l0 -80 50 0 c28 0 50 5 50 10 0 6 -18 10 -40 10 -36 0
-40 3 -40 25 0 22 4 25 35 25 44 0 46 18 3 22 -25 2 -34 8 -36 26 -3 20 1 22
37 22 23 0 41 5 41 10 0 6 -22 10 -50 10 l-50 0 0 -80z"/>
<path d="M7115 3024 c-23 -23 -15 -36 38 -63 26 -13 47 -30 47 -37 0 -24 -31
-29 -63 -10 -37 21 -50 11 -18 -15 30 -25 66 -24 91 1 27 27 13 52 -40 74 -24
9 -40 22 -37 29 6 19 47 20 68 1 15 -14 19 -14 19 -3 0 39 -73 54 -105 23z"/>
<path d="M7468 3023 c-23 -28 -11 -51 35 -65 48 -14 61 -27 45 -45 -15 -18
-36 -16 -69 8 -23 16 -29 17 -29 5 0 -21 39 -46 71 -46 25 0 59 27 59 47 0 17
-35 42 -69 49 -22 5 -31 12 -29 23 4 23 46 26 69 5 25 -22 33 -14 13 14 -19
27 -74 30 -96 5z"/>
<path d="M4415 2899 c-4 -6 -5 -13 -2 -16 7 -7 27 6 27 18 0 12 -17 12 -25 -2z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
